import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import MenuItemList from "./menu_item_list";
import MenuItemModal from "./menu_item_modal";
function CatMenuList({
  direction,
  scrollId,
  products,
  language,
  sectionRefs,
  setscrollSpyElement,
  currentElementIndexInViewport,
  currency,
}) {
  const [selectedData, setSelectedData] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleSelectedData = (datac) => {
    setSelectedData(datac);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    setscrollSpyElement(currentElementIndexInViewport);
    // eslint-disable-next-line
  }, [currentElementIndexInViewport]);
  return (
    <>
      <Row
        className="mx-2"
        id={`section-${scrollId}`}
        ref={sectionRefs[scrollId]}
        dir={direction}
      >
        {products?.map((item, index) => {
          return (
            <MenuItemList
              key={"menuitemlist-" + index}
              onSelect={handleSelectedData}
              product={item}
              language={language}
              currency={currency}
            />
          );
        })}
      </Row>
      {selectedData != null && (
        <MenuItemModal
          product={selectedData}
          show={showModal}
          handleClose={handleCloseModal}
          language={language}
          direction={direction}
          currency={currency}
        />
      )}
    </>
  );
}

export default CatMenuList;
